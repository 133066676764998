<template>
  <b-card

      :img-src="album.cover"
      :img-alt="album.title"
      img-top
      no-body
  >
    <!--      :img-src="require('@/assets/images/slider/02.jpg')"-->
    <div class="album-cover-badge" v-show="album.rating">
      <div class="profile-img-container d-flex align-items-center">
        <div class="profile-img">
          <rating-badge :rating="album.rating"></rating-badge>
        </div>
      </div>
    </div>

    <div class="album-cover-votes-badge" v-show="album.votes_count">
      <div class="profile-img-container d-flex align-items-center">
        <div class="profile-img">
          <span>
            <b-badge
                :variant="(album.votes_count > 2) ? 'success' : 'warning'"
                class="badge-glow"
            >
              <feather-icon icon="StarIcon"/>
              {{ album.votes_count }}
            </b-badge>
          </span>
        </div>
      </div>
    </div>

    <!-- body -->
    <b-card-body>
      <b-card-title>{{ album.title }}
        <b-link :href="album.url" target="_blank">
          <feather-icon icon="ExternalLinkIcon"/>
          Ссылка
        </b-link>
      </b-card-title>
      <!--        <b-card-text>
                Some quick example text to build on the card title.
              </b-card-text>-->
      <b-row>
        <b-col
            md="6"
            sm="6"
            class="mb-2 mb-md-0 mb-xl-0"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                  size="48"
                  variant="light-primary"
              >
                <feather-icon
                    size="24"
                    icon="DiscIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ album.tracks_count }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Треков
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
            md="6"
            sm="6"
            class="mb-2 mb-md-0 mb-xl-0"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                  size="48"
                  variant="light-success"
              >
                <feather-icon
                    size="24"
                    icon="CalendarIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ album.release_date | formatDate }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Релиз
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>

      <app-collapse v-if="album.yandex_id">
        <app-collapse-item title="Альбом">
          <iframe style="border:none;width:100%;height:450px;" width="100%" height="450"
                  :src="'https://music.yandex.ru/iframe/#album/' + album.yandex_id"></iframe>
        </app-collapse-item>
      </app-collapse>
    </b-card-body>


    <b-form-rating
        v-model="album.rating"
        show-clear
        show-value

        stars="10"
        variant="warning"
        size="lg"
        @change="vote()"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BCardBody,
  BFormRating,
  BBadge,
  BRow,
  BCol,
  BCardHeader, BMedia, BAvatar, BMediaBody, BMediaAside,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem'
import moment from 'moment'
import RatingBadge from '@/views/vote/RatingBadge'

/*eslint-disable */
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    BCardBody,
    AppCollapse,
    AppCollapseItem,
    BFormRating,
    BBadge,
    BRow,
    BCol,
    BCardHeader,
    BMedia,
    BMediaBody,
    BMediaAside,
    BAvatar,
    RatingBadge,
  },
  props: ['album'],
  data() {
    return {}
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },
  },
  methods: {
    vote: function (event) {
      console.log('RATING CHANGED: ' + this.album.rating)
      this.$http.post('/vote/album',
          {
            rating: this.album.rating,
            object_id: this.album.id,
          },
      ).then(res => {
        console.log('ITS OK')
      })
    },
  },

}
</script>
